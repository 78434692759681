/*
  Optimized load of Google Fonts to improve speed in Lighthouse reports
  As explained here:
  https://csswizardry.com/2020/05/the-fastest-google-fonts/

  [1].Preemptively warm up the fonts’ origin.

  [2].Initiate a high-priority, asynchronous fetch for the CSS file. Works in most modern browsers.

  [3].Initiate the fonts synchronous fetch that gets applied to the page only after it’s arrived.

  [4].In the unlikely event that a visitor has intentionally disabled JavaScript, fall back to the original method.
      The good news is that, although this is a render-blocking request, it can still make use of the preconnect
      which makes it marginally faster than the default.
*/

import React, { Component } from "react"
import { Helmet } from "react-helmet"

export default class GoogleFonts extends Component {
  render() {
    const googleFontUrl =
      "https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500,600,700,800,900&amp;display=swap&amp;subset=latin-ext"

    return (
      <Helmet>
        {/* [1] */}
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* [2] */}
        <link rel="preload" as="style" href={googleFontUrl} />

        {/* [3] */}
        <link rel="stylesheet" href={googleFontUrl} />

        {/* [4] */}
        <noscript>{`<link rel="stylesheet" href=${googleFontUrl} />`}</noscript>
      </Helmet>
    )
  }
}
